import React from 'react';

const EyeSVG: React.FC = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      viewBox="0 0 83.43 83.43"
    >
      <g>
        <path
          d="M82.316,39.792C73.947,25.331,58.39,16.348,41.715,16.348c-16.676,0-32.232,8.982-40.603,23.444L0,41.715l1.113,1.924
  c8.369,14.46,23.926,23.443,40.602,23.443c16.675,0,32.232-8.982,40.602-23.443l1.113-1.924L82.316,39.792z M42.132,55.354
  c-8.206,0-14.883-6.676-14.883-14.883c0-8.206,6.677-14.883,14.883-14.883s14.883,6.677,14.883,14.883
  C57.015,48.678,50.338,55.354,42.132,55.354z M8.95,41.715c3.54-5.372,8.314-9.717,13.812-12.76
  c-2.016,3.377-3.196,7.307-3.196,11.516c0,6.01,2.375,11.458,6.216,15.508C19.014,52.951,13.123,48.048,8.95,41.715z
  M59.113,55.281c3.465-3.967,5.582-9.141,5.582-14.811c0-3.898-0.992-7.566-2.739-10.768c4.956,2.992,9.265,7.065,12.522,12.012
  C70.611,47.584,65.265,52.225,59.113,55.281z M47.988,40.179c0,3.464-2.81,6.272-6.272,6.272c-3.465,0-6.273-2.809-6.273-6.272
  c0-3.465,2.809-6.273,6.273-6.273C45.179,33.906,47.988,36.714,47.988,40.179z"
        />
      </g>
    </svg>
  );
};

export default EyeSVG;

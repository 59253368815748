import React, { FunctionComponent } from 'react';

const CollapseSVG: FunctionComponent = () => (
  <svg
    width="100%"
    height="100%"
    version="1.1"
    viewBox="0 0 20 20"
    x="0px"
    y="0px"
    className="ScIconSVG-sc-1bgeryd-1 cMQeyU"
  >
    <path d="M16 16V4h2v12h-2zM6 9l2.501-2.5-1.5-1.5-5 5 5 5 1.5-1.5-2.5-2.5h8V9H6z" />
  </svg>
);

export default CollapseSVG;

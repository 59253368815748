import React from 'react';

const TrophySvg: React.FC = () => {
  return (
    <svg
      type="color-fill-current"
      width="20px"
      height="20px"
      version="1.1"
      viewBox="0 0 20 20"
      x="0px"
      y="0px"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 10h.1A5.006 5.006 0 009 13.9V16H7v2h6v-2h-2v-2.1a5.006 5.006 0 003.9-3.9h.1a3 3 0 003-3V4h-3V2H5v2H2v3a3 3 0 003 3zm2-6h6v5a3 3 0 11-6 0V4zm8 2v2a1 1 0 001-1V6h-1zM4 6h1v2a1 1 0 01-1-1V6z"
      />
    </svg>
  );
};

export default TrophySvg;

export const MOCKS = {
  STREAMS: [
    {
      id: '0',
      user_id: 'user_id_123',
      title: 'asd',
      thumbnail_url: 'asdas',
      game_id: '123123',
      tag_ids: ['asdasd'],
      user_name: 'asasdasd',
    },
    {
      id: '0',
      user_id: 'user_id_123',
      title: 'asd',
      thumbnail_url: 'asdas',
      game_id: '123123',
      tag_ids: ['asdasd'],
      user_name: 'asasdasd',
    },
    {
      id: '0',
      user_id: 'user_id_123',
      title: 'asd',
      thumbnail_url: 'asdas',
      game_id: '123123',
      tag_ids: ['asdasd'],
      user_name: 'asasdasd',
    },
    {
      id: '0',
      user_id: 'user_id_123',
      title: 'asd',
      thumbnail_url: 'asdas',
      game_id: '123123',
      tag_ids: ['asdasd'],
      user_name: 'asasdasd',
    },
    {
      id: '0',
      user_id: 'user_id_123',
      title: 'asd',
      thumbnail_url: 'asdas',
      game_id: '123123',
      tag_ids: ['asdasd'],
      user_name: 'asasdasd',
    },
  ],
};
